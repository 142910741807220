import { useEffect } from 'react';

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
const keys = { 37: 1, 38: 1, 39: 1, 40: 1, 33:1, 34: 1, 35: 1, 36: 1 };

export function preventDefault(e: any) {
  e = e || window.event;
  if (e.preventDefault) {
    e.preventDefault();
  }
  e.returnValue = false;
}
export function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

export const useDisableScroll = ()=> {
  useEffect(()=> {
    if (typeof window === 'undefined') return;
    let supportsPassive = false;
    try {
      window.addEventListener('test', null, Object.defineProperty({}, 'passive', {
        get() {
          supportsPassive = true;
          return true;
        },
      }));
    } catch (e) {/**/}

    let wheelOpt = supportsPassive ? { passive: false } : false;
    let wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
    return ()=> {
      window.removeEventListener(wheelEvent, preventDefault, false); // modern desktop
      window.removeEventListener('touchmove', preventDefault, false); // mobile
      window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
    };
  }, []);
};

export default useDisableScroll;
