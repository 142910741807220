import { useRef, useState, useEffect } from 'react';

export const useIsElementInViewport = <T = HTMLImageElement>(options?: IntersectionObserverInit) => {
  const elementRef = useRef<T>();
  const [isVisible, setIsVisible] = useState(false);

  let observer;

  const callback = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
    if (entry.isIntersecting) {
      try {
        observer.unobserve(elementRef.current);
      } catch (e) {/**/}
    }
  };

  useEffect(() => {
    observer = new IntersectionObserver(callback, options);
    // @ts-ignore
    elementRef.current && observer.observe(elementRef.current);
    return () => {
      try {
        observer.disconnect();
      } catch (e) {/**/}
    };
  }, [elementRef, options]);

  return { elementRef, isVisible };
};
