import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useIsElementInViewport } from '@hooks/useIsElementInViewport';
import { AutoNextImage, AutoNextImageContainer } from './styled';
const normalizeSrc = (src) => src[0] === '/' ? 'https://www.acon3d.com/' + src.slice(1) : src;

const cloudflareLoader = ({ src, width, quality, watermark }: {
  src: string;
  width: number;
  quality: number;
  watermark?: boolean | string;
}) => {
  //  gif 확장자의 경우 최적화 하지 않음
  if (src.endsWith('.gif')) return src;

  let watermarkQuery = '';
  if (watermark) {
    watermarkQuery += '&watermark=true';
    if (typeof watermark === 'string') watermarkQuery += `&watermark-image-url=${watermark}`;
  }
  return `${process.env.NEXT_PUBLIC_IMAGEOPTIMIZER}/?image=${normalizeSrc(src)}&width=${width}&quality=${quality || 85}${watermarkQuery}`;
};

export const AutoImage = (props) => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const { width, style, src, watermark, unoptimized: unoptimizedFromProps, ...rest } = props;
  const [unoptimized, setUnoptimized] = useState(unoptimizedFromProps
    || src.startsWith('/imgs')
    || src.includes('.gif'));

  const { elementRef: imgRef, isVisible } = useIsElementInViewport({
    rootMargin: '600px 0px',
  });

  let timeout = null;
  useEffect(() => {
    if (!imgRef?.current || !props.checktimeout) return;
    const img = imgRef.current;
    timeout = setTimeout(function() {
      if (!img.complete) { // @ts-ignore
        props.onError && props.onError({ target: img });
        img.setAttribute('data-timeout', 'true');
      }
    }, 1000);
    return ()=> {
      clearTimeout(timeout);
    };
  }, [imgRef.current]);

  useEffect(()=> {
    if (unoptimizedFromProps === false
      || !src
      || ['png', 'gif', 'jpg', 'jpeg'].some(ext => src.includes(`.${ext}`))) return;
    axios.head(src).then((res)=> {
      if (res.headers['content-type'] === 'image/gif') {
        setUnoptimized(true);
      }
    });
  }, [src]);

  const placeholder = props.src.startsWith('data:image') ? 'empty' : 'blur';

  const sources = unoptimized ? {
    src,
  } : {
    src: cloudflareLoader({ src: src, width: parseInt(String(width), 10), quality: props.quality || 85, watermark }),
    srcSet: `${cloudflareLoader({ src: src, width: parseInt(String(width), 10), quality: props.quality || 85, watermark })} 1x, `
      + `${cloudflareLoader({ src: src, width: parseInt(String(width), 10) * 2, quality: props.quality || 85, watermark })} 2x, `
      + `${cloudflareLoader({ src: src, width: parseInt(String(width), 10) * 3, quality: props.quality || 85, watermark })} 3x`,
  };

  const onErrorEnhanced = (event)=> {
    setUnoptimized(true);
    if (event.target?.srcset?.includes('cdn-cgi/image')) {
      event.target.src = '';
      event.target.srcset = '';
      return;
    }
    props?.onError && props.onError(event);
  };

  const notInViewport = {
    src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8vAkAAl4Bh+J6dBIAAAAASUVORK5CYII=',
  };

  return (
    <AutoNextImageContainer style={style}>
      <AutoNextImage
        {...rest}
        ref={imgRef}
        // lazyBoundary={'600px'}
        placeholder={placeholder}
        onError={onErrorEnhanced}
        onLoad={props.onLoad}
        // layout="fill"
        // objectFit="contain"
        // unoptimized={isError}
        {...(isVisible ? sources : notInViewport)}
      />
    </AutoNextImageContainer>
  );
};
